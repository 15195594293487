import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActiveElement, Chart, ChartConfiguration, ChartEvent } from 'chart.js';
import { 
  Milestone, Moment, ReportDetails, StudentDashboardSettings, 
  ViewerConfigService, moment 
} from '../../../../lib';
import { DisplayMilestones } from '../dashboard2.component';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-behavior-chart',
  templateUrl: './behavior-chart.component.html',
  styleUrls: ['./behavior-chart.component.css']
})
export class BehaviorChartComponent {
  private _milestones: DisplayMilestones[];
  get milestones() { return this._milestones; }
  @Input() set milestones(val: DisplayMilestones[]) {
    this._milestones = val;
    if(this.chartObj) {
      this.generateEventLines(this.chartObj);
    }
  }

  id = uuid().toString();

  @Input() chart: ChartConfiguration;
  @Input() startDate: Moment;
  @Input() endDate: Moment;
  @Input() settings: StudentDashboardSettings;
  @Input() reportData: ReportDetails;
  @Input() scope: string;
  @Input() isDotChart: boolean = false;
  @Input() enablePrint: boolean = false;
  @Input() height: string = '300';
  @Input() width: string = '400';
  @Input() handleNav = true;
  @Output() changeDay: EventEmitter<Moment> = new EventEmitter();

  @ViewChild('chartMilestones')
  milestoneSvg: ElementRef<SVGElement>;
  @ViewChild('graphingElement')
  canvas: ElementRef<HTMLCanvasElement>;

  get DailyChartHeight() {
    if( this.viewerConfigService.isMobile ) {
      return window.innerWidth;
    }
    if(window.innerWidth < 1000) {
      return 500;
    }

    return 100;
  }

  chartObj: Chart;
  inlinePlugin = [{
    id: `boundaryPlugin${uuid()}`,
    afterDraw: (chart: Chart) => {
      if(chart.canvas.id == this.id) {
        this.chartObj = chart;
        this.generateEventLines(chart);
      }
    }
  }];

  constructor(protected viewerConfigService: ViewerConfigService) {
  }

  ngOnInit() {
    setTimeout(() => {
      if(!this.chartObj) {
        return;
      }
      this.generateEventLines(this.chartObj);
    }, 200);
  }

  generateEventLines(chart?: Chart) {
    const svg = this.milestoneSvg?.nativeElement;

    if(!chart) {
      chart = this.chartObj;
    }
    this.chartObj = chart;

    if(!svg) {
      return;
    }

    const yAxis = chart?.scales['yAxis'];
    const xAxis = chart?.scales['xAxis'] ?? chart?.scales['x'];
    if(!yAxis || !xAxis) {
      return;
    }
    const canvas = this.canvas.nativeElement;
    const rect = canvas.getBoundingClientRect();
    const chartBounds = chart.chartArea;
    const boundary = {
      top: yAxis.paddingTop,
      bottom: canvas.clientHeight - yAxis.paddingBottom,
      left: chartBounds.left,
      right: chartBounds.right,
      width: chartBounds.right - chartBounds.left,
      height: canvas.clientHeight - xAxis.height
    };
    boundary.width = boundary.right - boundary.left;
    const svgRect = svg.getBoundingClientRect();
    if(rect.width != svgRect.width || rect.height != svgRect.height) {
      svg.style.width = rect.width + 'px';
      svg.style.height = rect.height + 'px';
    }
    if(boundary.width > rect.width || (boundary.width < 100 && rect.width > 200)) {
      return;
    }
    if(boundary.left + boundary.width < rect.width) {
      boundary.width = rect.width - boundary.left - (boundary.width - (boundary.right - boundary.left));
    }

    svg.innerHTML = '';

    if (!this.milestones || this.milestones.length === 0) {
      return;
    }

    this.milestones?.map(x => {
      const date = moment(x.date);
      let onDate = moment(this.startDate);
      let endDate = moment(this.endDate);
      const isDotChart = this.chart.type == 'bubble' || this.chart.type == 'scatter';
      let offset = -1;
      let total = -1;
      while(onDate.isBefore(endDate, 'day')) {
        if(!isDotChart) {
          const dateFormat = onDate.format('MM/DD/yyyy');
          if(this.reportData.includeDays.find(inDate => dateFormat == inDate)) {
            if(onDate.isSameOrBefore(date, 'day')) {
              offset++;
            }
            total++;
          } else if(this.settings.autoExcludeDays.findIndex(z => z == onDate.weekday()) < 0) {
            if(onDate.isSameOrBefore(date, 'day')) {
              offset++;
            }
            total++;
          }  
        } else {
          if(onDate.isSameOrBefore(date, 'day')) {
            offset++;
          }
          total++;
        }
        onDate.add(1, 'day');
      }
      if(total != 7) {
        let z = 0;
      }
      
      const left = boundary.left;
      const height = boundary.height;
      const width = boundary.right - boundary.left;
      const top = boundary.top;
      const xValue = boundary.left + ((width / total) * offset);
      svg.innerHTML += `<line x1="${xValue}px" y1="${top}px" x2="${xValue}px" y2="${height}px" style="stroke:${x.color};stroke-width:20;" />`;
    });

    console.log('Id', svg.id);
  }

  goToReportDetailsFromLine(event: { event?: ChartEvent, active?: ActiveElement[] }, adjustOffset: boolean) {
    if (!this.handleNav) {
        return;
    }
    if (!event || !event.active[0]) {
        return;
    }
    let x = event.active[0].index;
    let scope = 'days';
    console.log("Active element index:", x);

    let dateOffset = 0;
    if (scope == 'Days' || scope == 'days') {
        if (adjustOffset) {
            let onDate = moment(this.startDate);
            console.log("Starting date:", this.startDate.format('MM/DD/yyyy'));
            for (var i = 0; i <= x; i++) {
                const dateFormat = onDate.format('MM/DD/yyyy');
                console.log(`Checking date: ${dateFormat}, at index: ${i}`);
                if (this.reportData.includeDays.find(inDate => dateFormat == inDate)) {
                    console.log(`Included day found: ${dateFormat}`);
                } else if (this.settings.autoExcludeDays.findIndex(z => z == i) >= 0) {
                    dateOffset++;
                    console.log(`Excluded day at index: ${i}, incrementing offset to: ${dateOffset}`);
                }

                onDate = onDate.add(1, 'days');
                console.log("Next date:", onDate.format('MM/DD/yyyy'));
            }
        }
    }
    const finalDate = moment(this.startDate).add(dateOffset + x, scope as any);
    console.log("Final calculated date:", finalDate.format('MM/DD/yyyy'));
    this.changeDay.emit(finalDate);
}

}
